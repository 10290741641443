import { Router } from '@reach/router';
import React, { useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FourOhFour from '../components/common/FourOhFour';
import LoadingDialog from '../components/common/LoadingDialog';
import { AuthContext } from '../context/AuthContext';
import { getUserInfo } from '../util/auth';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import EventSectionDetails from '../components/event-sections/EventSectionDetails';
import { setCookie } from '../util/cookie';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const ConferenceDays = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConferenceDaysRoutes />
    </QueryClientProvider>
  );
};

const ConferenceDaysRoutes = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  if (params.has('token')) {
    setCookie('access_token', params.get('token') as string);
    const refreshUser = async () => {
      const user = await getUserInfo();
    };
    refreshUser().then(() => {
      navigate('/members/profile');
    });
  }

  if (auth.authState === undefined) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingDialog />
      </div>
    );
  }

  return (
    <>
      <Layout>
        <ToastContainer />
        <Router basepath="conference-days">
          <FourOhFour default path="not-found" />
          <EventSectionDetails path=":sectionId" />
        </Router>
      </Layout>
    </>
  );
};

export default ConferenceDays;
